import { Box, Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { PictureGrid } from "../components/PictureGrid";
import { SZCZESLIWICKA_IMAGE_DICT } from "../dictionaries/SzczesliwickaImageDict";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

export const SzczesliwickaPage = () => {
  const navigate = useNavigate();

  return (
    <Box maxWidth="1200px" width="100%">
      <Helmet>
        <title>Górka Szczęśliwicka</title>
        <meta name="description" content="Górka Szczęśliwicka" />
        <meta
          name="keywords"
          content="Górka Szczęśliwicka, Korona Warszawy, odznaka Korona Warszawy, Korona Gór Warszawy, odznaka turystyczna"
        />
        <link
          rel="canonical"
          href="https://koronawarszawy.waw.pl/gorkaszczesliwicka"
        />
      </Helmet>
      <Box sx={{ mt: 4 }}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate("/goragnojna")}
        >
          <ArrowBack />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ mx: 0.5 }}
        >
          Korona Warszawy
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => navigate("/gorkakazurka")}
        >
          <ArrowForward />
        </Button>
      </Box>

      <Typography variant="h3" sx={{ my: 3 }}>
        Górka Szczęśliwicka
      </Typography>

      <Box sx={{ textAlign: "start", p: 1, width: "100%" }}>
        <Typography variant="h5" sx={{ py: 1 }}>
          1. Informacje ogólne
        </Typography>
        <Typography variant="body1" paragraph={true}>
          Górka Szczęśliwicka to jedno z najbardziej rozpoznawalnych wzniesień w
          Warszawie, położone w dzielnicy Ochota, na terenie Parku
          Szczęśliwickiego. Ze swoją wysokością 152 m n.p.m. (ok. 30 m wysokości
          względnej) jest najwyższym sztucznym wzniesieniem w stolicy.
        </Typography>

        <Typography variant="h5" sx={{ py: 1 }}>
          2. Historia
        </Typography>
        <Typography variant="body1" paragraph={true}>
          Górka Szczęśliwicka jest przykładem przekształcenia terenów
          powojennych zniszczeń w miejsce o wysokiej wartości rekreacyjnej. Jej
          historia sięga trudnych czasów odbudowy Warszawy po II wojnie
          światowej.
        </Typography>

        <Typography variant="h6" sx={{ pb: 1 }}>
          Początki: od gruzowiska do wzniesienia
        </Typography>
        <Typography variant="body1" paragraph={true}>
          Po zakończeniu wojny Warszawa leżała w gruzach – zniszczenia dotknęły
          około 85% zabudowy miasta. Powstało ogromne wyzwanie związane z
          usuwaniem gruzu i resztek budynków. Władze miejskie zadecydowały o
          stworzeniu kilku wysypisk, gdzie zwożono gruz. Jednym z takich miejsc
          była właśnie dzisiejsza lokalizacja Górki Szczęśliwickiej.
        </Typography>
        <Typography variant="body1" paragraph={true}>
          W latach 40. i 50. XX wieku teren ten pełnił funkcję składowiska
          materiałów powojennych, głównie cegieł, betonu i metalu. Gruz
          przywożono z różnych części miasta, a na przestrzeni lat jego ilość
          rosła. Wysypisko stało się z czasem charakterystycznym wzniesieniem,
          choć wtedy nie pełniło żadnej funkcji użytkowej i było jedynie
          miejscem technicznego składowania odpadów.
        </Typography>

        <Typography variant="h6" sx={{ pb: 1 }}>
          Transformacja w latach 60. i 70.
        </Typography>
        <Typography variant="body1" paragraph={true}>
          W połowie lat 60. rozpoczęto proces rekultywacji tego terenu. Władze
          miejskie, chcąc poprawić wizerunek i funkcjonalność dzielnicy Ochota,
          zadecydowały o przekształceniu dawnego wysypiska w zieloną przestrzeń
          rekreacyjną. Gruz i odpady przykryto grubą warstwą ziemi, a na
          powierzchni zaczęto sadzić drzewa i krzewy, które miały wzmocnić
          skarpy oraz nadać miejscu estetyczny wygląd.
        </Typography>
        <Typography variant="body1" paragraph={true}>
          Na początku lat 70. pojawiły się pierwsze plany zagospodarowania
          terenu w celach rekreacyjnych. Wokół Górki zaczęto wytyczać ścieżki
          spacerowe, a całość włączono w nowo powstały Park Szczęśliwicki. W ten
          sposób wzniesienie zaczęło nabierać znaczenia jako element
          krajobrazowy dzielnicy, przyciągając mieszkańców szukających zieleni i
          przestrzeni do odpoczynku.
        </Typography>

        <Typography variant="h6" sx={{ pb: 1 }}>
          Przełomowe lata 90.: całoroczny stok narciarski
        </Typography>
        <Typography variant="body1" paragraph={true}>
          Najważniejszy etap rozwoju Górki Szczęśliwickiej miał miejsce w latach
          90. To wtedy na jej zboczu zbudowano unikalny w skali Polski
          całoroczny stok narciarski. Inwestycja ta była odpowiedzią na rosnące
          zainteresowanie sportami zimowymi w Warszawie, ale jednocześnie
          ograniczone możliwości wyjazdów w góry dla wielu mieszkańców stolicy.
        </Typography>
        <Typography variant="body1" paragraph={true}>
          Stok narciarski pokryto specjalnymi matami syntetycznymi, które
          umożliwiają zjazdy niezależnie od pory roku. Wyciągi krzesełkowe i
          orczykowe zamontowane na Górce zwiększyły jej dostępność i
          atrakcyjność. Powstała infrastruktura przyciągnęła nie tylko
          narciarzy, ale także snowboardzistów i miłośników sportów
          ekstremalnych.
        </Typography>

        <Typography variant="h5" sx={{ py: 1 }}>
          3. Teraźniejszość
        </Typography>
        <Typography variant="body1" paragraph={true}>
          Dziś Górka Szczęśliwicka oferuje wiele atrakcji:
          <ul style={{ marginTop: 0 }}>
            <li>
              Całoroczny stok narciarski – pokryty specjalnymi matami, umożliwia
              jazdę na nartach i snowboardzie nawet w ciepłe miesiące. Wyciąg
              krzesełkowy i orczykowy ułatwiają dostęp do szczytu.
            </li>
            <li>
              Tor saneczkowy – ulokowany na zboczu Górki, jest idealny dla
              dzieci i rodzin.
            </li>
            <li>
              Park Szczęśliwicki – otaczający wzniesienie park to spokojne
              miejsce spacerów, idealne na pikniki i relaks nad wodą.{" "}
            </li>
            <li>
              Ścieżki spacerowe i rowerowe – pozwalają na aktywne spędzenie
              czasu na łonie natury.
            </li>
            <li>
              Place zabaw i siłownie plenerowe – atrakcyjne zarówno dla dzieci,
              jak i dorosłych.{" "}
            </li>
            <li>
              Punkty widokowe – z których można podziwiać panoramę Warszawy,
              zwłaszcza w pogodny dzień.
            </li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ py: 1 }}>
          4. Informacje praktyczne dla odwiedzających
        </Typography>
        <Typography variant="h6" sx={{ pb: 1 }}>
          Zdjęcie do weryfikacji
        </Typography>
        <Typography variant="body1" paragraph={true}>
          Najwyższy punkt Górki Szczęśliwickiej znajduje się na terenie
          zamkniętego ośrodka narciarskiego. Dostęp do niego jest płatny i nie
          zawsze możliwy. Z tego względu do weryfikacji zdobycia Górki
          Szczęśliwickiej akceptujemy zdjęcia wykonane w dowolnym miejscu na
          terenie wznienienia. Przykładowo, może być to zdjęcie zrobione:
          <ul style={{ marginTop: 0 }}>
            <li>u szczytu polany z widokiem na centrum Warszawy</li>
            <li>
              pod murem ośrodka narciarskiego (dostęp od strony południowej)
            </li>
          </ul>
        </Typography>
        <Typography variant="h6" sx={{ pb: 1 }}>
          Dojazd komunikacją miejską
        </Typography>
        <Typography variant="body1" paragraph={true}>
          Do Górki Szczęśliwickiej można łatwo dotrzeć autobusami i tramwajami.
          Najbliższe przystanki to:
          <ul style={{ marginTop: 0 }}>
            <li>Przystanek Korotyńskiego – linie autobusowe 157, 167, 521</li>
            <li>Przystanek Dickensa – linie tramwajowe 7, 9, 15</li>
          </ul>
        </Typography>

        <Typography variant="h6" sx={{ pb: 1 }}>
          Parkingi
        </Typography>
        <Typography variant="body1" paragraph={true}>
          W pobliżu Parku Szczęśliwickiego znajdują się dogodne parkingi przy
          ulicach Włodarzewskiej oraz Drawskiej. Parkowanie jest możliwe także w
          okolicznych osiedlach z zachowaniem zasad strefy płatnego parkowania.
        </Typography>
      </Box>

      <Box sx={{ mt: 1 }}>
        <PictureGrid
          imageDict={SZCZESLIWICKA_IMAGE_DICT}
          useButtons={false}
          showHeader={true}
        />
      </Box>
    </Box>
  );
};
