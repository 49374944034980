import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MailIcon } from "../icons/MailIcon";
import { FacebookIcon } from "../icons/FacebookIcon";
import { isIOS, isMobile } from "react-device-detect";
import { TikTokIcon } from "../icons/TikTokIcon";

export const HomePage = () => {
  return (
    <Box maxWidth="1200px" sx={{ mt: 1 }}>
      <Helmet>
        <title>Korona Warszawy</title>
        <meta name="description" content="Korona Warszawy" />
        <meta
          name="keywords"
          content="Korona Warszawy, Korona Gór Warszawy, odznaka Korona Warszawy, odznaka turystyczna, Górka Szczęśliwicka, Góra Gnojna, Kopiec Moczydłowski, Kopa Cwila, Kopiec Powstania Warszawskiego, Wzgórze Trzech Szczytów, Górka Kazurka, Kazurka, Kazurówka, Kazoorka, Góra Kazury, Monte Kazuro, Góra Latawcowa"
        />
        <link rel="canonical" href="https://koronawarszawy.waw.pl" />
      </Helmet>

      <Box sx={{ justifyContent: "end", display: "flex", marginRight: "8px" }}>
        <IconButton sx={{ p: "0 0 0 4px" }}>
          <Tooltip title="Przejdź na Facebook">
            <a
              href={
                isMobile
                  ? isIOS
                    ? "fb://profile/147584975110790"
                    : "fb://page/147584975110790"
                  : "https://www.facebook.com/61552741180439/"
              }
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon width={"32px"} height={"32px"} />
            </a>
          </Tooltip>
        </IconButton>
        <IconButton sx={{ p: "0 0 0 4px" }}>
          <Tooltip title="Przejdź na TikTok">
            <a
              href="https://www.tiktok.com/@koronawarszawy/"
              target="_blank"
              rel="noreferrer"
            >
              <TikTokIcon width={"32px"} height={"32px"}></TikTokIcon>
            </a>
          </Tooltip>
        </IconButton>
        <IconButton sx={{ p: "0 0 0 4px" }}>
          <Tooltip title="Wyślij email">
            <a href="mailto: kontakt@koronawarszawy.waw.pl">
              <MailIcon width={"32px"} height={"32px"}></MailIcon>
            </a>
          </Tooltip>
        </IconButton>
      </Box>
      <Box sx={{ marginTop: "-32px" }}>
        <h2 className="page-title">Korona Warszawy</h2>
      </Box>
      <Box sx={{ textAlign: "start", p: 1, mt: 3 }}>
        <Typography variant="body1" paragraph={true}>
          Koronę Warszawy tworzy sześć najwyższych wzniesień znajdujących się na
          terenie stolicy:
        </Typography>

        <ul>
          <li>
            <Link href="/gorkaszczesliwicka" color="inherit">
              Górka Szczęśliwicka (152 m n.p.m.)
            </Link>
          </li>
          <li>
            <Link href="/gorkakazurka" color="inherit">
              Wzgórze Trzech Szczytów (133,9 m n.p.m.)
            </Link>
          </li>
          <li>
            <Link href="/kopiecmoczydlowski" color="inherit">
              Kopiec Moczydłowski (130 m. n.p.m.)
            </Link>
          </li>
          <li>
            <Link href="/kopiecpowstania" color="inherit">
              Kopiec Powstania Warszawskiego (121 m. n.p.m.)
            </Link>
          </li>
          <li>
            <Link href="/kopacwila" color="inherit">
              Kopa Cwila (108 m n.p.m.)
            </Link>
          </li>
          <li>
            <Link href="/goragnojna" color="inherit">
              Góra Gnojna (101 m n.p.m.)
            </Link>
          </li>
        </ul>

        <Typography variant="body1" paragraph={true}>
          Warszawskie wzniesienia nie są pochodzenia naturalnego i nie
          odznaczają się wybitnie dużą wysokością względną. Niemniej ich
          zdobywanie może sprawiać tyle samo radości, co wyprawy w prawdziwe
          góry. Może być motywacją do podjęcia wysiłku fizycznego, w formie i
          intensywności dopasowanej do wieku, stanu zdrowia i możliwości niemal
          każdej osoby. Szczyty Korony Warszawy idealnie nadają się zarówno do
          rodzinnych rekreacyjnych spacerów jak i treningu sportowego. W czasie
          wycieczek można odwiedzić wiele ciekawych, a często mało znanych
          miejsc w stolicy, przy okazji poznając ich historię. Zdobycie Korony
          Warszawy może też stanowić wstęp i inspirację do wyjazdu w góry.
        </Typography>

        <Typography
          variant="h4"
          paragraph={true}
          sx={{ textAlign: "start", pt: 2, mb: 0 }}
        >
          Odznaki Korona Warszawy i Zimowa Korona Warszawy
        </Typography>

        <Grid container spacing={2} sx={{ mb: 2, mt: 0, pt: 0 }}>
          <Grid item md={8} sm={7} xs={12}>
            <Typography variant="body1" paragraph={true}>
              Korona Warszawy to także nazwa programu odznaki turystycznej
              przyznawanej za zdobycie wszystkich tych szczytów. Zasady są
              zbliżone do innych projektów koron górskich. Jedyna różnica, że
              tutaj nie ma książeczki służącej do dokumentowania odbytych
              wycieczek oraz nie wymaga się zbierania potwierdzeń zdobycia
              szczytu w postaci pieczątek.
            </Typography>
            <Typography variant="body1" paragraph={true}>
              Uczestnik programu, który w opisany poniżej sposób udokumentuje
              fakt zdobycia wszystkich sześciu szczytów Korony Warszawy, będzie
              mógł nabyć pamiątkową odznakę. Otrzyma też imienną Kartę Zdobywcy
              z nadanym numerem, a jego imię i nazwisko zostanie dopisane do{" "}
              <a href="/zdobywcy">Listy Zdobywców.</a>
            </Typography>
            <Typography variant="body1" paragraph={true}>
              Projekt odznaki Korona Warszawy jest całoroczny. Uruchomiliśmy też
              osobny program odznaki Zimowowej Korony Warszawy. Odznakę tę
              przyznaje się za wejście na każdy z sześciu szczytów Korony
              Warszawy w typowych zimowych warunkach (śnieg, lód, szron, mróz).
              Obowiązuje kryterium pogodowe, a nie kalendarzowe – wejścia mogą
              odbywać się nawet w listopadzie czy kwietniu, jeśli tylko warunki
              są zimowe. Podstawą weryfikacji jest zdjęcie przedstawiające
              uczestnika na szczycie, z widocznymi elementami zimowej aury (np.
              śnieg, szron, lód). W razie potrzeby, podczas weryfikacji można
              się posiłkować zdjęciami wykonanymi poza szczytem (np.
              przedstawiające zamarzniętą taflę stawu w parku), ale muszą być to
              zdjęcia z tej samej wycieczki. Odznaka zimowa jest w kolorze
              srebrnym (w odróżnieniu od złotej odznaki programu podstawowego).
            </Typography>
            <Typography variant="body1" paragraph={true}>
              Lista Zdobywców Zimowej Korony Warszawy będzie prowadzona osobno,
              ale ukończenie projektu zimowego automatycznie oznacza uzyskanie
              tytułu Zdobywcy także w projekcie podstawowym. Zdobywca może
              wybrać, czy odbiera tylko odznakę Zimową (srebrną), czy odznaki
              obu projektów (srebrną i złotą). Jeśli odznaka podstawowa została
              już odebrana wcześniej, uczestnik otrzymuje tylko odznakę Zimową.
            </Typography>
          </Grid>

          <Grid item md={4} sm={5} xs={12}>
            <img
              className="picture-img"
              src="/images/odznaka.png"
              alt="Odznaka Korona Warszawy"
            />
          </Grid>
        </Grid>

        <Typography variant="body1" paragraph={true}>
          Zasady zdobywania Odznaki Korona Warszawy w skrócie wyglądają tak:
        </Typography>

        <ol>
          <li>
            <Typography variant="body1" paragraph={true}>
              W dowolnym czasie, w dowolnej kolejności i w dowolny sposób
              (pieszo, biegowo, na rowerze, na nartach, itp - o ile jest to
              zgodne z miejscowym prawem) uczestnik projektu zdobywa szczyty
              poszczególnych wzniesień tworzących Koronę Warszawy.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph={true}>
              W najwyższym ogólnodostępnym punkcie danego wzniesienia uczestnik
              robi zdjęcie (np. selfie), na którym oprócz jego osoby będzie
              widoczny także jakiś charakterystyczny obiekt znajdujący się na
              szczycie (np. pomnik, element małej architektury), ewentualnie w
              tle widok zawierający rozpoznawalne elementy krajobrazu Warszawy.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph={true}>
              Po skompletowaniu wszystkich sześciu szczytów Korony Warszawy,
              uczestnik wysyła kompletną dokumentację zdjęciową na adres mailowy{" "}
              <a href="mailto: kontakt@koronawarszawy.waw.pl">
                kontakt@koronawarszawy.waw.pl
              </a>
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph={true}>
              Po pozytywnej weryfikacji dostarczonych zdjęć, administrator
              programu kontaktuje się z uczestnikiem w celu ustalenia formy
              wysyłki odznaki (Poczta Polska, kurier, paczkomat) oraz informuje
              go o związanych z tym kosztach (cena odznaki plus koszty wysyłki).
            </Typography>
            <Typography variant="body1" paragraph={true}>
              Cena złotej odznaki całorocznego projektu Korona Warszawy to 30
              zł.
            </Typography>
            <Typography variant="body1" paragraph={true}>
              Cena srebrnej odznaki projektu Zimowa Korona Warszawy to 40 zł.
            </Typography>
            <Typography variant="body1" paragraph={true}>
              Cena, oprócz samej odznaki, obejmuje też imienną Kartę Zdobywcy
              oraz wpis na internetową Listę Zdobywców.
            </Typography>
            <Typography variant="body1" paragraph={true}>
              Aktualne koszty przesyłki:
              <ul>
                <li>
                  Poczta Polska - list zwykły ekonomiczny: 4,90 zł /
                  priorytetowy: 5,80 zł
                </li>
                <li>
                  Poczta Polska - list polecony ekonomiczny: 7,80zł /
                  priorytetowy: 9,80 zł
                </li>
                <li>paczkomat Inpost: 11,99 zł</li>
              </ul>
            </Typography>
            <Typography variant="body1" paragraph={true}>
              Sprzedaż odznak odbywa się wyłącznie w systemie wysyłkowym. Nie ma
              możliwości odbioru osobistego.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph={true}>
              Uczestnik programu dokonuje wpłaty ustalonej sumy w formie
              przelewu na rachunek bankowy administratora projektu:
              <Typography variant="body1">
                odbiorca: AMkod Michał Mielniczuk
              </Typography>
              <Typography variant="body1">
                nr konta: 41 1240 6087 1111 0011 2388 4641
              </Typography>
              <Typography variant="body1" paragraph={true}>
                tytuł przelewu: „Odznaka Korona Warszawy – imię i nazwisko
                uczestnika”
              </Typography>
              <Typography variant="body1" paragraph={true}>
                Wpłaty mogą być realizowane wyłącznie w formie przelewu. Na
                razie nie ma możliwości płatności gotówką lub kartą.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography variant="body1" paragraph={true}>
              Po otrzymaniu przelewu administrator programu wysyła odznakę na
              podany przez uczestnika adres oraz umieszcza jego dane na{" "}
              <a href="/zdobywcy">Liście Zdobywców.</a>
            </Typography>
          </li>
        </ol>

        <Typography variant="body1" paragraph={true}>
          Szczegółowe zasady przyznawania odznaki Korona Warszawy określa{" "}
          <a href="/docs/regulamin.pdf" target="_blank">
            regulamin
          </a>
          .
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <img
          className="picture-img"
          src="/images/insygnia.jpg"
          alt="Odznaka Korona Warszawy"
        />
      </Box>
    </Box>
  );
};
